import React, { Component } from 'react'
import './App.css'
import './Styleguide.css'

class App extends Component {
  render() {
    return (
      <div className="section">
        <header className="App-header">
          <h1>Some rokka fun</h1>
          <p>
            Powered by <a href="https://rokka.io">rokka</a>.
          </p>
          <p>
            <a href={'./drupal'}>Happy 20th birthday Drupal!</a>
          </p>
          <p>
            <a href={'./easter'}>Liip Easter 2021</a>
          </p>
        </header>

        <footer style={{ marginBottom: '500px' }}>
          <p>
            (Additionally powered by <a href="https://reactjs.org/">React</a>{' '}
            and some AWS S3/CloudFront.
          </p>
        </footer>
      </div>
    )
  }
}

export default App
