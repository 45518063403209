import React, { Component } from 'react'
import rokka from 'rokka'
import { defaultFields, org } from './App'

//const renderHost = 'http://test.rokka.test/'
class Image extends Component {
  renderHost = `https://${org}.rokka.io/`
  rka = new rokka({
    renderHost: this.renderHost,
    //  apiHost: 'http://api.rokka.test/',
  })

  getImage(url) {
    return (
      <div className={'glitchImage'}>
        <a href={url} target="liipPicsFew">
          <img
            src={url}
            id="images"
            width="100%"
            style={{ maxWidth: this.props.fields.width + 'px' }}
            alt=""
          />
        </a>
      </div>
    )
  }

  render() {
    let gridClass = 'grid__item sm-w-1/2 lg-w-3/4'
    if (this.props.formHidden) {
      gridClass = 'grid__item sm-w-2/3 lg-w-2/3'
    }

    const url = this.rka.render.addStackVariables(
      `${this.renderHost}/e/${this.props.fields.hash}/happy.jpg`,

      Object.assign(
        {},
        {
          s:
            this.props.fields.secondary !== defaultFields.secondary
              ? this.props.fields.secondary
              : undefined,
          a:
            this.props.fields.anchor !== defaultFields.anchor
              ? this.props.fields.anchor
              : undefined,
          w:
            this.props.fields.width !== defaultFields.width
              ? this.props.fields.width
              : undefined,
        }
      )
    )

    const path = url.replace(this.renderHost, '/')

    const v = { title: 'Happy Easter!', url: path }
    const cardUrl = `${this.renderHost}_card/?v=${encodeURIComponent(
      JSON.stringify(v)
    )}`
    const twitterText = encodeURIComponent(`Happy Easter!

Generate your own using @rokkaio https://rokka-fun.liip.to/easter

`)
    const twitterIntent = `https://twitter.com/intent/tweet?text=${twitterText}&hashtags=liip&url=${encodeURIComponent(
      cardUrl
    )}`

    return (
      <div className="grid grid--large grid--multiline grid--top">
        <div className={gridClass}>
          {this.getImage(url)}

          <p>
            <a target={'_blank'} rel="noreferrer" href={twitterIntent}>
              Tweet it.
            </a>
          </p>
          <p>
            Link to image: <a href={url}>{url}</a>
          </p>
        </div>
      </div>
    )
  }
}
export default Image

/**

*/
