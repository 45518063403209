import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import AppDrupal from './Drupalize/App'
import AppEaster from './Easter/App'
const AppMutabor = React.lazy(() => import('./Mutabor/App'))

if (window.location.pathname.startsWith('/drupal')) {
  ReactDOM.render(<AppDrupal />, document.getElementById('root'))
  document.title = 'Happy 20th birthday Drupal! with rokka.io'
} else if (window.location.pathname.startsWith('/easter')) {
  ReactDOM.render(<AppEaster />, document.getElementById('root'))
  document.title = 'Liip Easter 2021'
} else if (
  window.location.pathname.startsWith('/mutabor') ||
  window.location.pathname.startsWith('/svg')
) {
  ReactDOM.render(
    <Suspense fallback={<div>Loading..</div>}>
      <AppMutabor />
    </Suspense>,
    document.getElementById('root')
  )
  document.title = 'SVG Overlay Demo 2022'
} else {
  ReactDOM.render(<App />, document.getElementById('root'))
}
