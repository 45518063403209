import React, { Component } from 'react'
import '../App.css'
import '../Styleguide.css'
import rokka from 'rokka'
import Image from './Image'
import UploadForm from './UploadForm'
import { debounce } from 'lodash'
// regular font 57abff
// bolf 200cdb
export const org = 'rokka-fun'
const apiKeyUpload = 'T23u1qjKj2Vx4TUcclu5B9a0Ij68Ju32'
export const defaultFields = {
  width: '800',
  secondary: 'd29d43',
  anchor: '190_370',
  hash: '040142',
}
class App extends Component {
  defaultFields = defaultFields

  debouncedRender = debounce(() => this.setRenderFields(), 200)

  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(window.location.search)
    const defaultFields = Object.assign({}, this.defaultFields)
    urlParams.forEach((value, key) => {
      defaultFields[key] = value
    })
    this.state = {
      comment: '',
      submitted: false,
      buttonText: 'Submit',
      formHidden: false,
      image: null,
      hash: null,

      formFields: Object.assign({}, defaultFields),
      renderFields: Object.assign({}, defaultFields),
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.rokkaAuthed = rokka({
      apiKey: apiKeyUpload,
    })
  }

  handleInputChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    if (target.type === 'file') {
      value = target.files[0]
    }
    const name = target.name
    if (name === 'width' && value > 1920) {
      value = 1920
    }
    const formFields = this.state.formFields

    if (name === 'secondary') {
      formFields['anchor'] = '240_150'
      if (value === 'e3547f') {
        formFields['anchor'] = '400_380'
      }
    }
    formFields[name] = value
    this.setState({
      formFields,
    })
    this.debouncedRender()
  }

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }
    const file = this.state.formFields.uploadImage
    const rokkaUpload = rokka({
      apiKey: apiKeyUpload,
      // apiHost: 'http://api.rokka.test/app_dev.php',
    })
    localStorage.setItem('name', this.state.name)
    if (typeof file === 'undefined' && this.state.formFields.hash === '') {
      alert('Please add a picture')
      return
    }
    if (
      (this.state.uploadFile &&
        this.state.uploadFile.name &&
        file.name === this.state.uploadFile.name) ||
      typeof file === 'undefined'
    ) {
      this.setRenderFields()
      return
    }

    this.setState({ buttonText: 'Uploading' })

    rokkaUpload.sourceimages
      .create(org, file.name, file, {
        meta_user: {
          tool: 'liip-glitch',
        },
        meta_dynamic: {
          detection_face: {},
        },
      })
      .then((result) => {
        const renderFields = this.state.formFields
        renderFields.hash = result.body.items[0].short_hash
        this.setRenderFields({
          buttonText: 'Submit',
          image: result.body.items[0],
          hash: result.body.items[0].short_hash,
          uploadFile: file,
          submitted: true,
        })
      })
      .catch((err) => {
        console.log('ERROR', err)
        this.setState({ buttonText: 'Error while uploading' })
      })
  }

  setRenderFields = (fields = {}) => {
    const urlParams = new URLSearchParams(window.location.search)

    for (let i in this.state.formFields) {
      if (i === 'uploadFile') {
        continue
      } else if (i === 'removeGif') {
        continue
      }
      urlParams.set(i, this.state.formFields[i])
    }
    window.history.pushState(
      undefined,
      'Happy Easter!',
      '?' + urlParams.toString()
    )

    fields.renderFields = Object.assign({}, this.state.formFields)
    this.setState(fields, () => {
      this.setState(fields)
    })
  }

  render() {
    var formDisplay = 'block'
    var imageClass = 'grid__item sm-w-1/1 lg-w-3/4'
    if (this.state.formHidden) {
      formDisplay = 'none'
      imageClass = 'grid__item sm-w-1/1 lg-w-1/1'
    }

    return (
      <div className="section">
        <header className="App-header">
          <h1>Happy Easter!</h1>
          <p>
            Powered by <a href="https://rokka.io">rokka</a>.
          </p>
        </header>
        <div className="grid grid--large grid--multiline grid--top">
          <div
            className="grid__item sm-w-1/1 lg-w-1/4"
            style={{ display: formDisplay }}
          >
            <UploadForm
              onSubmit={this.handleSubmit}
              submitted={this.state.submitted}
              buttonText={this.state.buttonText}
              fields={this.state.formFields}
              onInputChange={this.handleInputChange}
            />
          </div>
          <div className={imageClass}>
            <Image
              org={org}
              formHidden={this.state.formHidden}
              fields={this.state.renderFields}
              defaultFields={this.defaultFields}
            />
          </div>
        </div>
        <footer style={{ marginBottom: '500px' }}>
          <p></p>
        </footer>
      </div>
    )
  }
}

export default App
